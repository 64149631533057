import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { format } from 'd3-format';
import { scaleTime } from 'd3-scale';
import { utcDay } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import {
  Chart,
  ChartCanvas,
} from 'react-stockcharts';
import {
  XAxis,
  YAxis,
} from 'react-stockcharts/lib/axes';
import {
  CrossHairCursor,
  CurrentCoordinate,
  MouseCoordinateX,
  MouseCoordinateY,
} from 'react-stockcharts/lib/coordinates';
import {
  CandlestickSeries,
  LineSeries,
} from 'react-stockcharts/lib/series';
import { OHLCTooltip } from 'react-stockcharts/lib/tooltip';
import { timeIntervalBarWidth } from 'react-stockcharts/lib/utils';

import { Alert } from '@mui/material';

import {
  fitFunction,
  FitType,
} from '../api/datafit';
import Spinner from '../components/Spinner';
import { DIGITAL_CURRENCY } from '../constants/crypto/digitalCurrency';
import MainLayout from '../layouts/MainLayout';

interface Data {
  date: Date;
  open: number;
  high: number;
  low: number;
  close: number;
  predicted: number;
}

interface FetchData {
  '1b. open (USD)': string;
  '2b. high (USD)': string;
  '3b. low (USD)': string;
  '4b. close (USD)': string;
}

enum Bot {
  SuperAwesomeBot = 'Super Awesome Bot',
  AwesomeBot = 'Awesome Bot',
  SuperBot = 'Super Bot',
  RealTimeBot = 'Real Time Bot',
}

const tickers = DIGITAL_CURRENCY.sort((a, b) => a.name.localeCompare(b.name));
const bots: Bot[] = [Bot.AwesomeBot, Bot.RealTimeBot];
const API_KEY = ['HRS6GKY5812Q2X3I', 'PTIURLMXFYGTLI2W'];

const mouseEdgeAppearance = {
  textFill: '#542605',
  stroke: '#05233B',
  strokeOpacity: 1,
  strokeWidth: 3,
  arrowWidth: 5,
  fill: '#BCDEFA',
};

const margin = { left: 0, right: 0, top: 20, bottom: 30 };

const width = 800;
const height = 600;

const gridHeight = height - margin.top - margin.bottom;
const gridWidth = width - margin.left - margin.right;

const showGrid = true;

const yGrid = showGrid ? { innerTickSize: -1 * gridWidth, tickStrokeOpacity: 0.2 } : {};
const xGrid = showGrid ? { innerTickSize: -1 * gridHeight, tickStrokeOpacity: 0.2 } : {};

function AICryptoPage() {
  const [apiIndex, setApiIndex] = useState<number>(0);
  const [ticker, setTicker] = useState('BTC');
  const [bot, setBot] = useState<Bot>(Bot.AwesomeBot);
  const [ordre, setOrdre] = useState(3);
  const [interval, setInterval] = useState(100);
  const [fitType, setFitType] = useState<FitType>(FitType.Polynomial);

  const [allDatas, setAllDatas] = useState<Data[]>([]);
  const [datas, setDatas] = useState<Data[]>([]);
  const [fitDatas, setFitDatas] = useState<Data[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [startIndex, setStartIndex] = useState<number>(100);

  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<'warning' | 'info'>('info');
  const [alertMessage, setAlertMessage] = useState<string>('');

  const [isPending, setIsPending] = useState<boolean>(true);

  // fetch datas from API
  // ticker, apiIndex effect
  useEffect(() => {
    if (bot !== Bot.RealTimeBot) {
      setIsPending(true);
      setIsAlert(false);
      setDatas([]);
      setFitDatas([]);

      fetch(`https://www.alphavantage.co/query?function=DIGITAL_CURRENCY_DAILY&symbol=${ticker}&market=USD&apikey=${API_KEY[apiIndex]}`)
        .then((res) => res.json())
        .then((res: any) => {
          const allDatas = Object.entries(res['Time Series (Digital Currency Daily)']).map((data) => {
            const [date, values] = data as [string, FetchData];
            const open = Number(values['1b. open (USD)']);
            const high = Number(values['2b. high (USD)']);
            const low = Number(values['3b. low (USD)']);
            const close = Number(values['4b. close (USD)']);
            return {
              date: new Date(date),
              open,
              high,
              low,
              close,
              predicted: (open + high + close + low) / 4,
            };
          });

          allDatas.reverse();

          setAllDatas(allDatas);

          const startIndex = allDatas.findIndex((data) => data.date.getTime() === startDate.getTime());
          setDatas(allDatas);
          // setDatas(allDatas.slice(0, startIndex));
          // setFitDatas(allDatas);
          // setIsPending(false);
        })
        .catch((err) => {
          setAlertSeverity('info');
          setAlertMessage('You have reached the limit. Please try again later.');
          setIsAlert(true);
          setIsPending(false);
          if (apiIndex < API_KEY.length - 1) {
            setApiIndex(apiIndex + 1);
          }
        });
    }
  }, [ticker, apiIndex, bot]);

  useEffect(() => {
    switch (bot) {
      case Bot.AwesomeBot:
        setIsAlert(false);
        setFitType(FitType.Polynomial);
        break;
      case Bot.RealTimeBot:
        setAlertSeverity('info');
        setAlertMessage('Real time bot is only available for paid users.');
        setIsAlert(true);
        setFitType(FitType.Null);
        break;
      default:
        break;
    }
  }, [bot]);

  // startDate effect
  // useEffect(() => {
  //   const startIndex = allDatas.findIndex((data: Data) => data.date.getTime() === startDate.getTime());
  //   setDatas(allDatas.slice(0, startIndex));
  // }, [startDate]);

  // startIndex effect
  // useEffect(() => {
  //   if (allDatas.length > startIndex) setStartDate(allDatas[startIndex].date);
  //   setDatas(allDatas.slice(0, startIndex));

  //   if (startIndex < allDatas.length) {
  //     setTimeout(() => {
  //       setStartIndex(startIndex + 1);
  //     }, 0);
  //   }
  // }, [startIndex]);

  // fit functions effect (datas, interval, ordre, bot, fitType)
  useEffect(() => {
    const dataLen = datas.length;
    if (dataLen > interval) {
      // fit actual datas
      const fitDatas: Data[] = datas.map((data: Data, index: number) => {
        if (index < interval) return data;

        // get data for fitting
        const xDatas = datas.map((d: Data) => d.date.getTime()).slice(index - interval, index);
        const yDatas = datas.map((d: Data) => (d.low + d.high + d.open + d.close) / 4).slice(index - interval, index);

        // get fitValue value
        const fitValue: number = Math.max(fitFunction(xDatas, yDatas, ordre, fitType).predict(data.date.getTime()), 0);

        // predicted value = fitValue value
        return { ...data, predicted: fitValue };
      });

      // predict future datas
      let lastDate = datas[dataLen - 1].date.getTime();
      const nextPrediction: Data[] = [];
      const predictLen = Math.floor(interval * 0.5);
      for (let i = 0; i < predictLen; i++) {
        // get data for fitting
        // first data for fitting = last data of actual datas
        const xDatas = datas.map((data: Data) => data.date.getTime()).slice(-interval + Math.min(i, Math.floor(interval * 0.5)));
        const yDatas = datas
          .map((data: Data) => (data.open + data.close + data.low + data.high) / 4)
          .slice(-interval + Math.min(i, Math.floor(interval * 0.5)));

        // add more data from predicted datas
        if (i > 0) {
          const nextPredictionLen = nextPrediction.length;
          for (let j = 0; j < nextPredictionLen; j++) {
            xDatas.push(lastDate + (j + 1) * 24 * 60 * 60 * 1000);
            yDatas.push(nextPrediction[j].predicted);
          }
        }

        // add predicted data
        const newDate = new Date(lastDate + (i + 1) * 24 * 60 * 60 * 1000);
        // minPredict = 50% of value at position -predictLen + i
        const minPredict = 0.5 * datas[dataLen - predictLen + i].low;

        // minPredict = 150% of value at position -predictLen + i
        const maxPredict = 1.5 * datas[dataLen - predictLen + i].high;

        const predicted = fitFunction(xDatas, yDatas, ordre, fitType).predict(newDate.getTime());

        const fitValue: number =
          predicted < datas[dataLen - 1].low
            ? Math.max(predicted, minPredict)
            : predicted > datas[dataLen - 1].high
            ? Math.min(predicted, maxPredict)
            : predicted;

        nextPrediction.push({
          date: newDate,
          open: fitValue,
          high: fitValue,
          low: fitValue,
          close: fitValue,
          predicted: fitValue,
        });
      }

      setFitDatas([...fitDatas, ...nextPrediction]);

      setIsPending(false);
    }
  }, [datas, interval, ordre, bot, fitType]);

  const getPredicted = useCallback((d: Data) => d.predicted, []);

  const xAccessor = useCallback((d: Data) => (d !== undefined ? d.date : new Date()), []);

  const xExtents = useMemo(() => {
    [xAccessor(fitDatas[0]), xAccessor(fitDatas[fitDatas.length - 1])];
  }, [fitDatas, xAccessor]);

  const handleChangeTicker = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setTicker(e.target.value);
  }, []);

  const handleChangeBot = useCallback((e: ChangeEvent<HTMLSelectElement>) => setBot(e.target.value as Bot), []);

  return (
    <MainLayout>
      <div className='flex flex-col items-center space-y-5'>
        {isAlert ? (
          <Alert severity={alertSeverity} className='w-5/6 md:w-1/3'>
            {alertMessage}
          </Alert>
        ) : null}
        <div className='flex flex-col space-y-5'>
          <div className='flex space-x-2 justify-between'>
            <label className='font-bold'>Market: </label>
            <div className='flex space-x-1'>
              <select value={ticker} onChange={handleChangeTicker}>
                {tickers.map((ticker) => (
                  <option key={ticker.name} value={ticker.value} className='text-end'>
                    {ticker.name}
                  </option>
                ))}
              </select>
              <div> / USD</div>
            </div>
          </div>
          <div className='flex space-x-2 justify-between'>
            <label className='font-bold'>Bot: </label>
            <select value={bot} onChange={handleChangeBot}>
              {bots.map((bot) => (
                <option key={bot} value={bot} className='text-end'>
                  {bot}
                </option>
              ))}
            </select>
          </div>

          {/* <div className='flex space-x-2 justify-between'>
            <label className='font-bold'>Function: </label>
            <select value={fitType} onChange={(e) => setFitType(e.target.value as FitType)}>
              {supportedFits.map((fit) => (
                <option key={fit} value={fit} className='text-end'>
                  {fit}
                </option>
              ))}
            </select>
          </div> */}

          {/* <div className='flex space-x-2 justify-between'>
            <label>Ordre:</label>
            <input
              type='number'
              value={ordre}
              onChange={(e) => setOrdre(Number(e.target.value))}
              placeholder='Degree'
            />
          </div> */}

          {/* <div className='flex space-x-2 justify-between'>
            <label>Interval:</label>
            <input
              type='number'
              value={interval}
              onChange={(e) => setInterval(Number(e.target.value))}
              placeholder='Interval'
            />
          </div> */}

          {/* <div className='flex space-x-2 justify-between'>
            <label>Start Date: </label>
            <div>{startDate.toISOString().split('T')[0]}</div>
            <input
              type='number'
              value={startIndex}
              onChange={(e) => setStartIndex(Number(e.target.value))}
              placeholder='Start Index'
              className='text-end'
            />
          </div> */}
        </div>

        <div>
          {isPending ? (
            <Spinner />
          ) : isAlert || fitDatas.length === 0 ? null : (
            <ChartCanvas
              height={height}
              width={width}
              ratio={1}
              margin={{ left: 50, right: 50, top: 10, bottom: 30 }}
              seriesName={ticker}
              data={fitDatas}
              xAccessor={xAccessor}
              displayXAccessor={xAccessor}
              xScale={scaleTime()}
              xExtents={xExtents}>
              <Chart id={1} yExtents={(d: Data) => [d.high, d.low]} padding={{ left: 0, right: 0, top: 30, bottom: 10 }}>
                <XAxis axisAt='bottom' orient='bottom' {...xGrid} />
                <YAxis axisAt='right' orient='right' {...yGrid} />

                <MouseCoordinateX at='bottom' orient='bottom' displayFormat={timeFormat('%Y-%m-%d')} rectRadius={5} {...mouseEdgeAppearance} />
                <MouseCoordinateY at='right' orient='right' displayFormat={format('.2f')} {...mouseEdgeAppearance} />

                <CandlestickSeries width={timeIntervalBarWidth(utcDay)} />
                <LineSeries yAccessor={getPredicted} stroke='#ff7f0e' />

                <CrossHairCursor />

                <CurrentCoordinate yAccessor={getPredicted} fill='#ff7f0e' />

                <OHLCTooltip origin={[10, 0]} />
              </Chart>
            </ChartCanvas>
          )}
        </div>
        <div className='w-full text-center'>
          <span className='font-bold'>Disclaimer:</span> <br />
          This application is an experimental research. <br />
          The algorithm is extremely complex. Don't try it at home. <br />
          The result may not be correct and should not be taken as financial advice. <br />
          The app owner has no responsibility for the results.
        </div>
      </div>
    </MainLayout>
  );
}

export default AICryptoPage;
