import regression from 'regression';

export interface FitResult {
  predict: (x: number) => number;
}

const nullFitResult: FitResult = {
  predict: (x: number) => 0,
};

export enum FitType {
  Default = 'default',
  Polynomial = 'polynomial',
  Linear = 'linear',
  Exponential = 'exponential',
  Logarithmic = 'logarithmic',
  Power = 'power',
  Null = 'null',
}

export const supportedFits: FitType[] = [FitType.Default, FitType.Linear, FitType.Polynomial, FitType.Logarithmic, FitType.Exponential, FitType.Power];

export const fitFunction = (xDatas: number[], yDatas: number[], order: number, fitType: FitType): FitResult => {
  switch (fitType) {
    case FitType.Polynomial:
      return polynomialFit(xDatas, yDatas, order);
    case FitType.Linear:
      return linearFit(xDatas, yDatas);
    case FitType.Exponential:
      return exponentialFit(xDatas, yDatas);
    case FitType.Logarithmic:
      return logarithmicFit(xDatas, yDatas);
    case FitType.Power:
      return powerFit(xDatas, yDatas);
    default:
      return nullFitResult;
  }
};

const defaultFit = (xDatas: number[], yDatas: number[], order: number): FitResult => {
  const polynomial: FitResult = polynomialFit(xDatas, yDatas, order);
  const logarithmic: FitResult = logarithmicFit(xDatas, yDatas);
  return {
    predict: (x: number) => (polynomial.predict(x) + logarithmic.predict(x)) / 2,
  };
};

const linearFit = (xDatas: number[], yDatas: number[]): FitResult => {
  const data: regression.DataPoint[] = [];
  for (let i = 0; i < xDatas.length; i++) {
    data.push([xDatas[i], yDatas[i]]);
  }
  const result = regression.linear(data);
  return {
    predict: (x: number) => result.predict(x)[1],
  };
};

const exponentialFit = (xDatas: number[], yDatas: number[]): FitResult => {
  const data: regression.DataPoint[] = [];
  for (let i = 0; i < xDatas.length; i++) {
    data.push([xDatas[i], yDatas[i]]);
  }
  const result = regression.exponential(data);
  return {
    predict: (x: number) => result.predict(x)[1],
  };
};

const logarithmicFit = (xDatas: number[], yDatas: number[]): FitResult => {
  const data: regression.DataPoint[] = [];
  for (let i = 0; i < xDatas.length; i++) {
    data.push([xDatas[i], yDatas[i]]);
  }
  const result = regression.logarithmic(data);
  return {
    predict: (x: number) => result.predict(x)[1],
  };
};

const powerFit = (xDatas: number[], yDatas: number[]): FitResult => {
  const data: regression.DataPoint[] = [];
  for (let i = 0; i < xDatas.length; i++) {
    data.push([xDatas[i], yDatas[i]]);
  }
  const result = regression.power(data);
  return {
    predict: (x: number) => result.predict(x)[1],
  };
};

const polynomialFit = (xDatas: number[], yDatas: number[], degree: number): FitResult => {
  const lhs = [];
  const rhs = [];
  for (let i = 0; i < degree + 1; i++) {
    for (let j = 0; j < degree + 1; j++) {
      lhs[i * (degree + 1) + j] = 0;
      for (let k = 0; k < xDatas.length; k++) {
        lhs[i * (degree + 1) + j] += Math.pow(xDatas[k], i + j);
      }
    }
  }
  for (let i = 0; i < degree + 1; i++) {
    rhs[i] = 0;
    for (let j = 0; j < xDatas.length; j++) {
      rhs[i] += yDatas[j] * Math.pow(xDatas[j], i);
    }
  }

  // gauss jordan elimination
  const n = rhs.length;
  for (let i = 0; i < n; i++) {
    const pivot = lhs[i * n + i];
    for (let j = 0; j < n; j++) {
      lhs[i * n + j] /= pivot;
    }
    rhs[i] /= pivot;
    for (let j = 0; j < n; j++) {
      if (j !== i) {
        const multiplier = lhs[j * n + i];
        for (let k = 0; k < n; k++) {
          lhs[j * n + k] -= multiplier * lhs[i * n + k];
        }
        rhs[j] -= multiplier * rhs[i];
      }
    }
  }

  const coef = rhs;
  const predict = (x: number) => {
    let y = 0;
    for (let i = 0; i < coef.length; i++) {
      y += coef[i] * Math.pow(x, i);
    }
    return y;
  };
  return { predict };
};
