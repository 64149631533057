export const DIGITAL_CURRENCY = [
  { name: 'Aave', value: 'AAVE' },
  { name: 'Cardano', value: 'ADA' },
  { name: 'AdEx', value: 'ADX' },
  { name: 'Algorand', value: 'ALGO' },
  { name: 'Ardor', value: 'ARDR' },
  { name: 'ATMChain', value: 'ATM' },
  { name: 'Cosmos', value: 'ATOM' },
  { name: 'Avalanche', value: 'AVAX' },
  { name: 'Basic-Attention-Token', value: 'BAT' },
  { name: 'Bitcoin-Cash', value: 'BCH' },
  { name: 'Binance-Coin', value: 'BNB' },
  { name: 'Bitcoin', value: 'BTC' },
  { name: 'BitShares', value: 'BTS' },
  { name: 'BitTorrent', value: 'BTT' },
  { name: 'PancakeSwap', value: 'CAKE' },
  { name: 'Dash', value: 'DASH' },
  { name: 'Dent', value: 'DENT' },
  { name: 'DigiByte', value: 'DGB' },
  { name: 'DogeCoin', value: 'DOGE' },
  { name: 'Polkadot', value: 'DOT' },
  { name: 'aelf', value: 'ELF' },
  { name: 'Enigma', value: 'ENG' },
  { name: 'Enjin-Coin', value: 'ENJ' },
  { name: 'EOS', value: 'EOS' },
  { name: 'Ethereum-Classic', value: 'ETC' },
  { name: 'Ethereum', value: 'ETH' },
  { name: 'Filecoin', value: 'FIL' },
  { name: 'FTX Token', value: 'FTT' },
  { name: 'FunFair', value: 'FUN' },
  { name: 'Gas', value: 'GAS' },
  { name: 'Golem-Tokens', value: 'GNT' },
  { name: 'Graph', value: 'GRT' },
  { name: 'Genesis-Vision', value: 'GVT' },
  { name: 'Hedera', value: 'HBAR' },
  { name: 'Hive', value: 'HVN' },
  { name: 'ICON', value: 'ICX' },
  { name: 'IOTA', value: 'IOTA' },
  { name: 'Kyber-Network', value: 'KNC' },
  { name: 'ChainLink', value: 'LINK' },
  { name: 'Litecoin', value: 'LTC' },
  { name: 'Terra', value: 'LUNA' },
  { name: 'Decentraland', value: 'MANA' },
  { name: 'Polygon', value: 'MATIC' },
  { name: 'NEO', value: 'NEO' },
  { name: 'Ontology', value: 'ONT' },
  { name: 'Polymath', value: 'POLY' },
  { name: 'Quantum', value: 'QAU' },
  { name: 'Qtum', value: 'QTUM' },
  { name: 'Revain', value: 'R' },
  { name: 'Radium', value: 'RADS' },
  { name: 'Augur', value: 'REP' },
  { name: 'Siacoin', value: 'SC' },
  { name: 'SHIBA-INU', value: 'SHIB' },
  { name: 'Solana', value: 'SOL' },
  { name: 'Steem', value: 'STEEM' },
  { name: 'Storj', value: 'STORJ' },
  { name: 'SysCoin', value: 'SYS' },
  { name: 'Theta-Token', value: 'THETA' },
  { name: 'Tronix', value: 'TRX' },
  { name: 'Uniswap', value: 'UNI' },
  { name: 'VeChain', value: 'VET' },
  { name: 'Wanchain', value: 'WAN' },
  { name: 'Waves', value: 'WAVES' },
  { name: 'NEM', value: 'XEM' },
  { name: 'Stellar', value: 'XLM' },
  { name: 'Monero', value: 'XMR' },
  { name: 'Tezos', value: 'XTZ' },
  { name: 'ZCoin', value: 'XZC' },
  { name: 'Zcash', value: 'ZEC' },
  { name: 'ZenCash', value: 'ZEN' },
  { name: 'Zilliqa', value: 'ZIL' },
  { name: 'Zilla', value: 'ZLA' },
  { name: '0x', value: 'ZRX' },
];
